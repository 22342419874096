/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.mainNav')
    .controller('MainNavCtrl', ['$scope', 'DataService',
      function($scope, DataService) {

        $scope.communities = [];

        var loadCommunities = function() {
          return DataService('/api/community', 'communities')
            .promise
            .then(function(communities) {
              $scope.communities = communities;
            });
        };

        loadCommunities();

      }]);
}());